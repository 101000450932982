import * as React from "react";
import styled from "styled-components";

const Paragraph = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0rem var(--padding-base);
`;
const Paragraph1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const Content1 = styled.div`
  width: 42.667rem;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0rem var(--padding-base);
  box-sizing: border-box;
`;
const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) 0rem;
  font-size: var(--heading-desktop-h5-size);
  font-family: var(--heading-desktop-h4);
`;
const WeCollectPersonal = styled.p`
  margin-block-start: 0;
  margin-block-end: 15px;
`;
const DirectlyFromYou = styled.li`
  margin-bottom: 0px;
`;
const AutomaticallyWeMay = styled.li``;
const DirectlyFromYouWhenYouVi = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
`;
const WeMayAlso = styled.p`
  margin: 0;
`;
const RichText = styled.div`
  width: 44.444rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 44.444rem;
`;
const Content3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;
const ContentRoot = styled.div`
  align-self: stretch;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl) var(--padding-93xl);
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--border-primary);
  font-family: var(--text-medium-bold);
`;

const PrivacyContent = () => {
  return (
    <ContentRoot>
      <Content3>
        <RichText>
          <Content>
            <Paragraph>
              IDM International LTD is committed to protecting the privacy of
              visitors to our website, idminternational.com.au. This Privacy
              Policy explains how we collect, use, and disclose your personal
              information when you use our website and the choices you have
              associated with your data.
            </Paragraph>
          </Content>
          <Content1>
            <Paragraph1>
              Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
              suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
              quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
              posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
              feugiat sapien varius id.
            </Paragraph1>
          </Content1>
          <Content2>
            <Heading>What Information Do We Collect?</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              <WeCollectPersonal>
                We collect personal information in a few ways:
              </WeCollectPersonal>
              <DirectlyFromYouWhenYouVi>
                <DirectlyFromYou>
                  Directly from you: When you visit our website and fill out
                  forms, such as contact forms, you may provide us with your
                  name, email address, and other contact information.
                </DirectlyFromYou>
                <AutomaticallyWeMay>
                  Automatically: We may collect information about your device
                  and how you use our website through cookies and other tracking
                  technologies. This information may include your IP address,
                  browser type, operating system, referring website, pages
                  visited, and time spent on the website.
                </AutomaticallyWeMay>
              </DirectlyFromYouWhenYouVi>
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>How Do We Use Your Information?</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              <WeCollectPersonal>
                We use your personal information for several purposes,
                including:
              </WeCollectPersonal>
              <DirectlyFromYouWhenYouVi>
                <DirectlyFromYou>
                  To respond to your inquiries and requests.
                </DirectlyFromYou>
                <DirectlyFromYou>
                  To process transactions and provide you with our services.
                </DirectlyFromYou>
                <DirectlyFromYou>
                  To send you information about our products and services, but
                  only if you have opted-in to receive such communications.
                </DirectlyFromYou>
                <AutomaticallyWeMay>
                  To improve our website and services by analyzing website usage
                  data.
                </AutomaticallyWeMay>
              </DirectlyFromYouWhenYouVi>
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>Will We Share Your Information?</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              <WeCollectPersonal>
                We may share your personal information with trusted third-party
                service providers who help us operate our website or provide
                services to our customers. These service providers are
                contractually obligated to protect your information and only use
                it for the purposes we have authorized.
              </WeCollectPersonal>
              <WeMayAlso>
                We may also disclose your personal information as required by
                law or to protect our legal rights.
              </WeMayAlso>
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>Your Choices and Rights</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              <WeCollectPersonal>
                You have several choices regarding your personal information:
              </WeCollectPersonal>
              <DirectlyFromYouWhenYouVi>
                <DirectlyFromYou>
                  Access and Correction: You have the right to request access to
                  the personal information we hold about you and to request that
                  it be corrected if it is inaccurate or incomplete.
                </DirectlyFromYou>
                <DirectlyFromYou>
                  Marketing Communications: You can choose not to receive
                  marketing communications from us by following the unsubscribe
                  instructions in any email we send you.
                </DirectlyFromYou>
                <AutomaticallyWeMay>
                  Cookies: You can adjust your browser settings to refuse
                  cookies. However, this may limit your ability to use certain
                  features of our website.
                </AutomaticallyWeMay>
              </DirectlyFromYouWhenYouVi>
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>Data Security</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              We take reasonable steps to protect your personal information from
              unauthorised access, disclosure, alteration, and destruction.
              However, no internet transmission or electronic storage system is
              completely secure.
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>Changes to this Privacy Policy</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the updated policy on our
              website.
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>Contact Us</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              If you have any questions about this Privacy Policy or your
              privacy rights, please contact us via the contact popup on this
              site.
            </Paragraph1>
          </Content>
        </RichText>
      </Content3>
    </ContentRoot>
  );
};

export default PrivacyContent;
