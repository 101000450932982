import * as React from "react";
import { useCallback } from "react";
import PrivacyHeader from "../components/privacy-header";
import PrivacyContent from "../components/privacy-content";
import Footer1 from "../components/footer1";
import styled from "styled-components";

const PrivacyPolicyRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const PrivacyPolicy = () => {

  return (
    <PrivacyPolicyRoot>
      <PrivacyHeader />
      <PrivacyContent />
      <Footer1 />
    </PrivacyPolicyRoot>
  );
};

export default PrivacyPolicy;
