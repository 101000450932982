import * as React from "react";
import { useState, useCallback } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import ContainerheaderMainNav from './containerheader-main-nav';

const IdmLogo22Icon = styled.img`
  width: 5.183rem;
  position: relative;
  height: 2.222rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const ImageLogo = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Buttonmuitext = styled(Button)`
  align-self: stretch;
  cursor: pointer;
`;
const AboutUs = styled(Button)``;
const Some = styled(MenuItem)``;
const Menu1 = styled(Menu)``;
const NavLinkDropdown = styled.div`
  align-self: stretch;
  cursor: pointer;
  &:hover (I12753:60056;12703:1097) {
    display: flex;
  }
`;
const Textp1 = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const NavLink = styled.div`
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-xs);
`;
const Textp2 = styled.p`
  margin: 0;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  z-index: 0;
`;
const VectorIcon = styled.img`
  width: 0.7rem;
  position: relative;
  height: 0.394rem;
`;
const ChevronDown = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-7xs);
  z-index: 1;
`;
const IconSmile = styled.img`
  width: 1.333rem;
  position: relative;
  height: 1.333rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const PageOne = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-small-link-size);
  line-height: 150%;
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const MenuItem1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0rem;
  gap: var(--gap-xs);
`;
const MenuItem2 = styled.div`
  width: 19.111rem;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0rem;
  box-sizing: border-box;
  gap: var(--gap-xs);
`;
const animationFadeInTop = keyframes`
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
const SubmenuAbout = styled.div`
  width: 20.444rem;
  margin: 0 !important;
  position: absolute;
  top: 3.889rem;
  left: -0.333rem;
  background-color: var(--color-gray-200);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-7xs);
  z-index: 2;
  font-size: var(--text-regular-normal-size);
  &:hover {
    display: flex;
    animation: 1s ease 0s 1 normal none ${animationFadeInTop};
    opacity: 1;
  }
`;
const NavLinkDropdown1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-xs);
  position: relative;
  gap: var(--gap-9xs);
`;
const ContainernavMenu = styled.nav`
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--text-regular-caps-size);
  color: var(--text-alternate);
  font-family: var(--text-medium-bold);
  @media screen and (max-width: 960px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const HamburgerMenuIcon = styled.img`
  width: 1.333rem;
  position: relative;
  height: 1.333rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  @media screen and (max-width: 960px) {
    display: flex;
  }
  @media screen and (max-width: 960px) {
    display: flex;
  }
`;
const AccountSection = styled.div`
  width: 1.333rem;
  height: 1.333rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ButtonmuicontainedText = styled.div`
  background-color: var(--primary-main);
  border: 1px solid var(--border-primary);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
`;
const IconChevronRight = styled.img`
  width: 1.333rem;
  position: relative;
  height: 1.333rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const ButtonmuitextIcon = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  gap: var(--gap-5xs);
  color: var(--primary-main);
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0rem 0rem 0rem var(--padding-21xl);
  gap: var(--gap-base);
  @media screen and (max-width: 960px) {
    padding-left: 0rem;
    box-sizing: border-box;
  }
`;
const ContainersectionMenuwrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
`;
const Navwrapper = styled.header`
  align-self: stretch;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0rem var(--padding-45xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--text-alternate);
  font-family: var(--text-medium-bold);
  @media screen and (max-width: 960px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const ContainerheaderMainnav = styled.header`
  align-self: stretch;
  background-color: var(--color-gray-300);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const PrivacyPolicy = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--heading-desktop-h1-size);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Container1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-101xl);
  box-sizing: border-box;
  max-width: 38.889rem;
`;
const HeaderRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("/header--442@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--text-medium-bold-size);
  color: var(--text-alternate);
  font-family: var(--text-medium-bold);
`;

const PrivacyHeader = () => {

  return (
    <HeaderRoot>
      <ContainerheaderMainNav />
      <Container1>
        <SectionTitle>
          <Textp1>Effective date: April 3, 2023</Textp1>
          <Content1>
            <PrivacyPolicy>Privacy Policy</PrivacyPolicy>
          </Content1>
        </SectionTitle>
      </Container1>
    </HeaderRoot>
  );
};

export default PrivacyHeader;
